.connect-wallet-modal{
  border-radius: 20px;
  .modal-content{
    height: 200px;
  }
  .modal-header {
    background-color: #03274499;
    backdrop-filter: blur(12px);
    // background: #032744;
    color: #fff;
    border-radius: 10px 10px 0px 0px;
    .modal-title{
      font-size: 18px;
    }
    a{
      color: #fff;
      font-size: 12px;
    }
  }
  .modal-body{
    background-color: #03274499;
    backdrop-filter: blur(12px);  
    // background: #032744;
    color: #fff;
    height: 100%;
    border-radius: 0px 0px 10px 10px;

    .connectWallet-list{
      li{
        background-color: transparent;
        cursor: pointer;
        color: #fff;
        border: none;
        &:hover{
          background: #07487099;
          border-radius: 0px 0px 0px 0px;
        }
      }
    }
  }
  .modal-content{
    background-color: transparent;
  }
}